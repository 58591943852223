/* styles.css */
body {
  background-image: url('/public/pink-sky.jpg'); /* Update the path to your image */
  background-size: cover; /* Cover the entire viewport */
  background-repeat: no-repeat; /* Prevent repeating */
  background-position: center; /* Center the image */
  min-height: 100vh; /* Make sure it covers the full height */
}


.container {
   background-color: #fffcfdf8;/* Optional: Add a semi-transparent background to containers */
  border-radius: 45px; /* Optional: Rounded corners */
  padding: 40px; /* Optional: Add some padding */
   min-height: calc(10vh - 10px); /* Adjust based on your footer height */

}

/* styles.css or App.css */




.navbar-expand-sm {
  flex-wrap: nowrap; /* Prevent wrapping */
  justify-content: flex-start; /* Align items to the left */
  
  
}

.navbar {
  padding: 10; 
}

.navbar-nav .nav-link {
  padding: 0.5rem 0.1rem; /* Minimal padding for links */
  font-size: 2rem; /* Smaller font size */
  margin: 0 80px; /* Adjust spacing between links */
}

.footer {
  padding: 2; /* Remove padding */
  font-size: 0.75rem; /* Smaller font size */
  background-color: #f6f8fa; /* Example background color */
  color: white; /* Text color */
}

.active-link {
  text-decoration: underline; /* Underline for the active link */
  font-weight: bold; /* Optional: Make it bold */
  color: rgb(219, 20, 176) !important ; /* Optional: Change color for the active link */
  font-style: italic;
}










